<template>
  <el-main>
    <!-- 搜索 -->
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="发布会员姓名：">
        <el-input size="small" placeholder="请输入发布人姓名" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" placeholder="请输入联系电话" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="发布时间：">
        <el-date-picker value-format="timestamp" v-model="form.time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="类别名：">
        <el-cascader :options="type_list" filterable clearable
        :props="{ value: 'id', label: 'name', children: 'data',checkStrictly:true,emitPath:false}"
        size="small" placeholder="请选择类别名（二级分类）" v-model="form.classify_ids"></el-cascader>
      </el-form-item>
      <el-form-item label="牌号名：">
        <el-input size="small" placeholder="请输入牌号名" v-model="form.brand_name"></el-input>
      </el-form-item>
      <el-form-item label="厂家名：">
        <el-input size="small" placeholder="请输入厂家名" v-model="form.supplier"></el-input>
      </el-form-item>
      <el-form-item label="价格类型：">
        <el-select v-model="form.price_type" placeholder="请选择价格类型" size="small" clearable>
          <el-option v-for="item in price_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区（省）：">
        <el-select v-model="form.province_id" placeholder="请选择地区" size="small" clearable filterable>
          <el-option v-for="item in arealist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="form.status" placeholder="请选择审核状态" size="small" >
          <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" @selection-change="selectionChange" ref="table">
      <template template slot="empty">
        <No />
      </template>
      <el-table-column type="selection" width="60"></el-table-column>
      <el-table-column prop="update_time" width="160" label="发布时间" align="center">
        <template slot-scope="scope">
          <span>{{ getDateformat(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="发布会员姓名" align="center" width="160"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" align="center" width="120"></el-table-column>
      <el-table-column prop="classify_name" label="类别名" align="center" width="160"></el-table-column>
      <el-table-column prop="brand_name" label="牌号名" align="center"></el-table-column>
      <el-table-column prop="supplier" label="厂家名" align="center"></el-table-column>
      <el-table-column prop="price_type" label="价格类型" align="center">
        <template slot-scope="scope">
          {{price_type.find(item=>item.value==scope.row.price_type).label}}
        </template>
      </el-table-column>
      <el-table-column prop="province_name" label="地区（省）" align="center" width="100"></el-table-column>
      <el-table-column prop="price" label="时价价格（元/吨）" width="140" align="center"></el-table-column>
      <el-table-column prop="status" label="审核状态" align="center">
        <template slot-scope="scope">
          <div :style="{'color':scope.row.status==1?'#E6A23C':scope.row.status==2?'#F56C6C':'#409EFF'}">{{status.find(item=>item.value==scope.row.status).label}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="scope.row.status==1" @click="handleAudit(scope.row.id,'2')">通过</el-button>
          <el-button type="text" size="small" v-if="scope.row.status==1" @click="handleAudit(scope.row.id,'3')">驳回</el-button>
          <el-button type="text" size="small" v-if="scope.row.status==3" @click="handleReason(scope.row.remark)">驳回原因</el-button>
          <el-button type="text" size="small" v-if="scope.row.status==2" @click="$router.push('/extension/currentprice/list')">查看列表</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData">
      <div slot="batch">
        <el-checkbox v-model="allchecked" style="margin-right: 20px" :indeterminate="isIndeterminate"
        @change="handleCheckAllChange">全选</el-checkbox>
        <el-link type="primary" :underline="false" @click="audit(true)">批量审核</el-link>
      </div>
    </Paging>
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="dialogVisible_audit" width="30%">
      <div class="mb20">请选择审核结果：</div>
        <div class="mb20">
          <el-radio v-model="form_audit.status" label="2">通过</el-radio>
          <el-radio v-model="form_audit.status" label="3">驳回</el-radio>
      </div>
      <template v-if="form_audit.status=='3'">
        <div class="mb10">请添加驳回原因：</div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="form_audit.remark"
        class="mb10"></el-input>
      </template>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_audit = false">取 消</el-button>
          <el-button type="primary" @click="confirmAudit">确 定</el-button>
      </span>
    </el-dialog>
     <!-- 驳回原因 -->
     <el-dialog title="审核" :visible.sync="dialogVisible_reason" width="30%" >
        <div class="dialog_main">
            {{reason}}
        </div>
      </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
import { price_type,status} from './data';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      form: {
        page: 1,
        row: 10,
        name:'',
        mobile:'',
        time:'',
        classify_ids:'',
        brand_name:'',
        supplier:'',
        price_type:'',
        province_id:'',
        status:'0'
      },
      price_type:price_type,
      status:status,
      arealist: [],
      total: 0,
      list: [],

      form_audit:{
        id:'',
        status:'2',
        remark:''
      },
      dialogVisible_audit:false,
      dialogVisible_reason:false,
      reason:'',
      type_list:[],
      choose_list: [],
      isIndeterminate: false,
      allchecked: false,
    };
  },
  created() {
    this.getList();
    this.getArea();
    this.getType();
  },
  methods: {
    search() {
      this.form.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.form = {
        page: 1,
        rows: 10,
        name: '',
        user: '',
        time: '',
        time2: '',
        tel: '',
        type: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.form.rows = val;
        this.getList();
      } else {
        this.form.page = val;
        this.getList();
      }
    },
    getList() {
      let data = Object.assign({},this.form)
      if(data.time){
        data.start_time = data.time[0]/1000
        data.end_time = data.time[1]/1000
        delete data.time
      }
      this.$axios.post(this.$api.push.currentprice.auditList,data)
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getDateformat,
    getArea() {
      this.$axios.get(this.$api.set.area, { pid: 0 }).then(res => {
        if (res.code == 0) {
          this.arealist = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getType() {
      this.$axios.post(this.$api.push.currentprice.setInfo).then(res => {
          if (res.code == 0) {
              this.type_list = res.result;
          } else {
              this.$message.error(res.msg);
          }
      });
    },
    handleAudit(id,status){
      this.form_audit.id = id
      this.form_audit.status = status
      this.dialogVisible_audit = true
    },
    handleReason(reason){
      this.reason = reason
      this.dialogVisible_reason = true
    },
    confirmAudit(){
      this.$axios.post(this.$api.push.currentprice.audit,this.form_audit).then(res => {
        if (res.code == 0) {
          this.$message.success('审核成功');
          this.dialogVisible_audit = false
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    selectionChange(val) {
      this.choose_list = val;
      this.isIndeterminate = val.length > 0 && val.length < this.list.length
      this.allchecked = val.length == this.list.length
    },
    handleCheckAllChange(val){
      this.isIndeterminate = false
      this.$refs.table.toggleAllSelection()
    },
    audit(is_audit){
      this.dialogVisible_audit = true
      this.is_audit= is_audit
    }
  },
};
</script>

<style lang="less" scoped>
.el-table {
  border-left: 1px solid #e8eef1;
  border-right: 1px solid #e8eef1;

  /deep/ .el-input__inner {
    border-color: #fff;
  }

  /deep/ .el-input__inner:focus {
    border-color: #409eff;
  }
}

.el-main {
  background: #fff;
}

/deep/ .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: '全选';
  margin-left: 8px;
}
.mb10{
  margin-bottom:10px;
}
.mb20{
  margin-bottom:20px;
}
.dialog_main{
  min-height: 200px;
}
</style>
